// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: 'AIzaSyCv5qT-waDVWtjgfVqHgA5o5r6BKyXvdLo',
    authDomain: 'quizzard-f6723.firebaseapp.com',
    projectId: 'quizzard-f6723',
    storageBucket: 'quizzard-f6723.appspot.com',
    messagingSenderId: '450692687041',
    appId: '1:450692687041:web:ab286a2781cbdcfcaaa040',
    measurementId: 'G-YLNNSGGDP1',
  },
  firebase2: {
    apiKey: "AIzaSyBp-NeCMuDGAvCm2WA_NIEGNGK-ycX_PUg",
    authDomain: "employee-c1ff1.firebaseapp.com",
    databaseURL: "https://employee-c1ff1.firebaseio.com",
    projectId: "employee-c1ff1",
    storageBucket: "employee-c1ff1.appspot.com",
    messagingSenderId: "865556745883",
    appId: "1:865556745883:web:13d37f08b43d84f095403e",
    measurementId: "G-MCMZQCR9KT"
  },
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
